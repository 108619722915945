import { DefaultSeoProps } from 'next-seo'

export const SEO: DefaultSeoProps = {
  titleTemplate: '%s | GodeFi Swap',
  defaultTitle: 'GodeFi Swap',
  description:
    'The most trusted decenteralized exchange platform powered by GodeFi Swap ecosystem.',
  twitter: {
    cardType: 'summary_large_image',
    handle: '@SwapOceanDex',
    site: '@SwapOceanDex',
  },
  openGraph: {
    title: 'GodeFi Swap - A next evolution DeFi exchange on BNB Smart Chain (BSC)',
    description:
      'The most trusted decenteralized exchange platform powered by GodeFi Swap ecosystem.',
    images: [{ url: 'https://i.ibb.co/8Pg3rPD/logo.png' }],
  },
}
